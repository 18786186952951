import toast from "react-hot-toast";
import { defineMessage } from "react-intl";

import { config } from "~/config";
import {
  VERIDA_CREDENTIAL_ZKPASS_SCHEMA_URLS,
  ZKPASS_INTRODUCTION_URL,
} from "~/features/activity/activities/zkpass/constants";
import type {
  Activity,
  ActivityOnExecute,
  ActivityOnInit,
  ActivityOnMessage,
} from "~/features/activity/types";
import { Logger } from "~/features/logger";
import { MISSION_05_ID } from "~/features/missions";
import { PARTNER_ID_ZKPASS } from "~/features/partners";
import { Sentry } from "~/features/sentry";
import {
  type ReceivedMessage,
  VAULT_CREDENTIAL_SCHEMA_URL,
  getMessaging,
  sendDataRequest,
} from "~/features/verida";

import { ACTIVITY_ID, ZKPASS_MEXC_OWNER_SCHEMA_ID } from "./constants";
import { verifyReceivedMessage } from "./utils";

const logger = new Logger("activity");

const handleNewMessage: ActivityOnMessage = async (
  message,
  _webUserRef,
  userActivity,
  saveActivity
) => {
  if (userActivity?.status === "completed") {
    return;
  }

  try {
    logger.info("Checking message", { activityId: ACTIVITY_ID });

    const verified = verifyReceivedMessage(message);
    if (!verified) {
      return;
    }

    logger.info(
      "Received message matched and verified, updating activity now",
      { activityId: ACTIVITY_ID }
    );

    await saveActivity({
      id: ACTIVITY_ID,
      status: "completed",
      data: {},
    });

    toast.success(
      "Congrats, you have completed the activity 'Prove ownership and claim a MEXC credential'"
    );
  } catch (error: unknown) {
    Sentry.captureException(error, {
      tags: {
        activityId: ACTIVITY_ID,
      },
    });
  }
};

const handleInit: ActivityOnInit = async (
  veridaWebUser,
  userActivity,
  saveActivity
) => {
  if (userActivity?.status === "completed") {
    logger.debug("Activity already completed, no initialisation needed", {
      activityId: ACTIVITY_ID,
    });
    return () => Promise.resolve();
  }

  logger.info("Initialising activity", { activityId: ACTIVITY_ID });

  const existingRequestId = userActivity?.data?.requestId;
  if (!existingRequestId) {
    logger.info("No existing request id, skipping", {
      activityId: ACTIVITY_ID,
    });
    return () => Promise.resolve();
  }

  try {
    logger.info("Getting Verida Context and Messaging", {
      activityId: ACTIVITY_ID,
    });

    const messaging = await getMessaging(veridaWebUser.current);

    const messages = (await messaging.getMessages()) as
      | ReceivedMessage<unknown>[]
      | undefined;

    const filteredMessages =
      messages?.filter(
        (message) => message.data.replyId === existingRequestId
      ) || [];

    if (filteredMessages.length === 0) {
      logger.info("No messages found for existing request id", {
        activityId: ACTIVITY_ID,
      });
      return () => Promise.resolve();
    }

    logger.info("Checking existing messages", {
      activityId: ACTIVITY_ID,
    });

    filteredMessages.some(async (message) => {
      try {
        logger.info("Checking message", { activityId: ACTIVITY_ID });

        const verified = verifyReceivedMessage(message);
        if (!verified) {
          return false;
        }

        logger.info(
          "Received message matched and verified, updating activity now",
          { activityId: ACTIVITY_ID }
        );

        await saveActivity({
          id: ACTIVITY_ID,
          status: "completed",
          data: {},
        });

        toast.success(
          "Congrats, you have completed the activity 'Prove ownership and claim a MEXC credential'"
        );

        return true;
      } catch (error: unknown) {
        Sentry.captureException(error, {
          tags: {
            activityId: ACTIVITY_ID,
          },
        });
        return false;
      }
    });
  } catch (error: unknown) {
    Sentry.captureException(error, {
      tags: {
        activityId: ACTIVITY_ID,
      },
    });
  }

  return () => Promise.resolve();
};

const handleExecute: ActivityOnExecute = async (veridaWebUser) => {
  logger.debug("Executing activity", { activityId: ACTIVITY_ID });

  try {
    const did = veridaWebUser.current.getDid();
    window.open(
      `${config.proof.connectorBaseUrl}?veridaDid=${did}&schemaId=${ZKPASS_MEXC_OWNER_SCHEMA_ID}`
    );

    // TODO: Make a localised message of this message
    const message =
      "Please share a MEXC account credential from zkPass protocol";

    logger.info("Sending data request", { activityId: ACTIVITY_ID });

    const sentMessage = await sendDataRequest(veridaWebUser.current, {
      messageSubject: message,
      requestSchema: VAULT_CREDENTIAL_SCHEMA_URL,
      filter: {
        "$or": VERIDA_CREDENTIAL_ZKPASS_SCHEMA_URLS.map((url) => ({
          credentialSchema: url,
        })),
        "credentialData.credentialSubject.zkPassSchemaId":
          ZKPASS_MEXC_OWNER_SCHEMA_ID,
      },
    });

    logger.info("Data request sent", {
      activityId: ACTIVITY_ID,
      hasRequestId: !!sentMessage?.id,
    });

    return {
      status: "pending",
      data: {
        requestId: sentMessage?.id,
      },
      message: defineMessage({
        id: "activities.claimMEXCOwnerzkPass.executePendingMessage",
        defaultMessage:
          "A request has been sent to your inbox. Please check your Verida Wallet inbox and share the credentials.",
        description:
          "Message explaining a request has been sent to your Verida Wallet inbox",
      }),
    };
  } catch (error: unknown) {
    Sentry.captureException(error, {
      tags: {
        activityId: ACTIVITY_ID,
      },
    });
    return {
      status: "todo",
      message: defineMessage({
        id: "activities.claimMEXCOwnerzkPass.gettingExecutionErrorMessage",
        defaultMessage: `There was an error while sending you the credential request, please try again later`,
        description: "Error message when we can't get the user profile",
      }),
    };
  }
};

export const activity: Activity = {
  id: ACTIVITY_ID,
  missionId: MISSION_05_ID,
  enabled: true,
  ended: false,
  visible: false,
  order: 1,
  points: 50,
  partners: [PARTNER_ID_ZKPASS],
  title: defineMessage({
    id: "activities.claimMEXCOwnerzkPass.title",
    defaultMessage: "Prove ownership and claim a MEXC credential",
    description:
      "Title of the activity 'Prove ownership and claim a MEXC credential'",
  }),
  description: defineMessage({
    id: "activities.claimMEXCOwnerzkPass.description",
    defaultMessage: `Prove ownership and claim a credential of your MEXC account. The credentials will be securely stored on the Verida Network, and can be shared and verified.`,
    description:
      "Description of the activity 'Prove ownership and claim a MEXC credential'",
  }),
  actionLabel: defineMessage({
    id: "activities.claimMEXCOwnerzkPass.actionLabel",
    defaultMessage: "Verify",
    description:
      "Label of the button to start the activity Claim MEXC ownership",
  }),
  actionReExecuteLabel: defineMessage({
    id: "activities.claimMEXCOwnerzkPass.actionReExecuteLabel",
    defaultMessage: "Verify again",
    description: "Label of the button to perform the activity again ",
  }),
  actionExecutingLabel: defineMessage({
    id: "activities.claimMEXCOwnerzkPass.actionExecutingLabel",
    defaultMessage: "Sending Request",
    description:
      "Label of the button when the activity 'Claim MEXC ownership' is being executed",
  }),
  onInit: handleInit,
  onExecute: handleExecute,
  onMessage: handleNewMessage,
  steps: [
    {
      order: 1,
      description: defineMessage({
        id: "activities.claimMEXCOwnerzkPass.step1.description",
        defaultMessage:
          "Click on the 'Verify' button and follow the instructions to perform the verification process. Once done, the proof will be sent to your inbox.",
        description:
          "Step 1 of the activity 'Prove ownership and claim a MEXC credential'",
      }),
    },
    {
      order: 2,
      description: defineMessage({
        id: "activities.claimMEXCOwnerzkPass.step2.description",
        defaultMessage:
          "Accept the proof credential received in your inbox to save the credential.",
        description:
          "Step 2 of the activity 'Prove ownership and claim a MEXC credential'",
      }),
    },

    {
      order: 3,
      description: defineMessage({
        id: "activities.claimMEXCOwnerzkPass.step3.description",
        defaultMessage:
          "Reply to the message sent by Verida Missions to share your new credential.",
        description:
          "Step 3 of the activity 'Prove ownership and claim a MEXC credential'",
      }),
    },
  ],
  resources: [
    {
      label: defineMessage({
        id: "activities.claimMEXCOwnerzkPass.resources.ZkPassPageUrl.label",
        defaultMessage: "User guide of zkPass protocol",
        description: "Label of the resource 'User guide of zkPass protocol'",
      }),
      url: ZKPASS_INTRODUCTION_URL,
    },
  ],
};
