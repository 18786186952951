/** URL of verida network site */
export const VERIDA_NETWORK_URL = "https://www.verida.network";

/** URL of the Verida Missions on Mainnet */
export const VERIDA_MISSIONS_MAINNET_URL = "https://missions.verida.network";

/** URL of the Verida Missions on Testnet */
export const VERIDA_MISSIONS_TESTNET_URL =
  "https://missions.testnet.verida.network";

/** URL of the form for reporting an issue */
export const ISSUE_REPORTING_FORM_URL = "https://forms.gle/g9UsJW7iRYfsRaUS7";

/** URL to download the Verida Wallet */
export const VERIDA_WALLET_DOWNLOAD_URL = "https://vault.verida.io/";

/** URL of an article about getting started on Verida Missions */
export const VERIDA_MISSIONS_GETTING_STARTED_GUIDE_URL =
  "https://community.verida.network/user-guides-and-support/getting-started-with-verida-missions";

/** URL of the VDA token page on verida.network */
export const VDA_TOKEN_PAGE_URL = "https://www.verida.network/vda-token";
